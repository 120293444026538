import React from 'react';
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';
import TableButton from '../components/TableButton';

var TableRow = createReactClass({
	getInitialState: function(){
		return{
			display:"none"
		}
	},
	actionUrl: function( url, data ) {
		var regExp = /\{([^}]+)\}/g;
		var matches = url && url.match(regExp);
		if(matches) {
			for (var i = 0; i < matches.length; i++){
				var str = matches[i];
				var c = str.substring(1, str.length - 1);
				for(var item in data) {
					if(item == c)
						url = url.replace(str, data[item]);
				}
			}
		}
		return url;
	},
	checkboxHandleChange: function(e) {
		this.props.onSelect(this.refs.selector.id,!this.props.selected);
	},
	_rowClickEvent: function( event ){
		var data = this.props.data;
		var rowUrl = this.props.controllerName;
		if( rowUrl) {
			var url = this.actionUrl(rowUrl, data);
			location.href = url;
		} else {
			event.stopPropagation();
		}
	},
	_rowHoverEvent: function( event ){
		var data = this.props.data;

		if(window.location.hash) {
			var hash = window.location.hash;
		}

		/*** This function is checking the preview function in user preference preview instant ***/
		var activePreviewShowInstantly = F('inboxPreviewShowInstantly');

		/*** This function is checking the preview function in user preference preview delay ***/
		var activePreviewDelay = F('inboxPreviewTimeDelay');

		/*** This function is checking the preview function in user preference preview ***/
		var inboxPreview = F('inboxPreview');

		/*** This function is checking the preview for search function in general system ***/
		var previewSearch = F('previewSearch');

		/*** This function is checking the preview for new-errands function in general system ***/
		var previewInbox = F('previewInbox');

		/*** This function is checking the preview for my-errands function in general system ***/
		var previewMyErrands = F('preview.my-errands');

		if(inboxPreview==false || (previewInbox==false && hash=="#new") || (previewMyErrands==false && hash=="#my") || (previewSearch==false && hash=="#search") ) {
			/*** Do nothing : No Preview/Disable ***/
		}
		else {
			if(this.props.onPreview) {
				this.props.onPreview(data.id);
			}
			if(activePreviewShowInstantly==true) {
				$("#itemRow_"+data.id+" > .subject .previewer").css('display','block')
			}
			else if(activePreviewShowInstantly==false && activePreviewDelay != null) {
				$("#itemRow_"+data.id+" > .subject").append("<img src='img/loading_animation_liferay.gif' class='loading' />");

				this.timeout = setTimeout(function(){
					$("#itemRow_"+data.id+" > .subject .loading").remove();
					$("#itemRow_"+data.id+" > .subject .previewer").css('display','block')
				}.bind(this), (activePreviewDelay*1000));
			}
		}
	},
	_rowHoverOutEvent: function( event ){
		if (this.timeout) {
			clearTimeout(this.timeout)
			this.timeout = null
		}
		var data = this.props.data;
		$("#itemRow_"+data.id+" > .subject .loading").remove();
		$("#itemRow_"+data.id+" > .subject .previewer").css('display','none')
	},
	render: function() {
		var columns = this.props.columns;
		var data = this.props.data;
		var action = this.props.actions;
		var controller = this.props.controllerName;
		var previewStyler = {
			textAlign: 'left',
			verticalAlign: 'middle',
			padding: '0px 14px',
			marginTop: '5px',
			marginBottom: '5px',
			display: 'inline-block'
		};
		var td = function( item ) {
			var previousErrand = {};
			var bgColor = '#FFF';
			var cellBgColor = '#FFF';
			var CellFontStyle = 'normal';
			var fontColor = '#000';
			return columns.map(function(c, i) {
				var defaultStyles = {
					cursor: 'pointer',
					verticalAlign: 'middle',
					fontStyle: CellFontStyle,
					backgroundColor: cellBgColor,
					color: fontColor
				};
				var styles = defaultStyles;
				if(item.style != undefined && Object.keys(item.style).length > 0){
					if(item.style.themeWarning != 'undefined'){
						if( item.style.themeWarning == "System Warning Alert Theme"){
							bgColor = '#F2AA52';
						} else if( item.style.themeWarning == "System Warning Theme"){
							bgColor = '#EA4866';
						}
					}
					[ 'fg', 'bg', 'bold', 'underline', 'italic', 'strike', 'smallcaps'].forEach(function( attr ){
						if( item.style && item.style[attr] ) {
							switch(attr){
								case 'fg': styles['color'] = item.style[attr]; break;
								case 'bg': styles['backgroundColor'] = item.style[attr]; break;
								case 'bold': styles['fontWeight'] = "bold"; break;
								case 'italic': styles['fontStyle'] = "italic"; break;
								case 'underline': styles['textDecoration'] = "underline"; break;
								case 'strike': styles['textDecoration'] = "line-through"; break;
								case 'smallcaps': styles['fontVariant'] = "small-caps"; break;
							}
						}
					});
				}
				if( item.groupWith != undefined && item.groupWith != 0){
					CellFontStyle 	= 'italic';
					fontColor = '#a0a0a0';
				}
				if( item.hasChild != undefined && item.hasChild != false ){
					fontColor = '#000';
				}
				var stylesStatus = {
					backgroundColor: bgColor,
					height: '30px !important'
				};
				var warningStatusBar = <span style={stylesStatus} className="divErrandStatusBar"></span>;
				if( c.type == 'checkbox' ) {
					var defaultChecked = false;
					return <td style={styles} key={"button" + i} ref="selector" id={item[c.key]}><input type={"checkbox"} name="id[]" className={"itemCheckbox_"+item[c.key]} id={item[c.key]} value={item[c.key]} defaultChecked={defaultChecked}  onClick={this.checkboxHandleChange} /></td>;
				}
				else if(c.type == 'image'){
					return <td style={styles} className={c.key} key={"image" + i}><div dangerouslySetInnerHTML={{__html: item[c.key]}} /></td>;
				}
				else if(c.type == 'errandStatusBar'){
					styles.width = "21px";
					return <td style={styles} className={c.key} key={"errandStatusBar" + i}>{warningStatusBar}</td>;
				}
				else if(c.type == 'iconicSubject'){
					item[c.key] = replacePM(item[c.key]);
					return <td style={styles} className={c.key} key={i} onClick={this._rowClickEvent} onMouseEnter={c.key == 'subject' ? this._rowHoverEvent : '' } onMouseLeave={c.key == 'subject' ? this._rowHoverOutEvent : ''}>
						<div className="errandPreviewSubject" style={previewStyler} dangerouslySetInnerHTML={{__html: item[c.key]}} />
						<div className={"previewer view_"+data.id} style={{ display : this.state.display }}>
							<div className='arrow-up'></div>
							<div className='pre_header'>{I('Preview')}</div>
							<div className='pre_title'>
								<span dangerouslySetInnerHTML={{__html: data.subject}} />
							</div>
							<div className={'pre_body'+data.id}></div>
						</div>

					</td>;
				}
				else if(c.type == 'donedate'){
					var elem = item[c.key].split(' ');
					var dateValue = elem[0];
					return <td style={styles} className={c.key} key={i} onClick={this._rowClickEvent}>{dateValue}</td>;
				}
				else{
					return <td style={styles} className={c.key} key={i} onClick={this._rowClickEvent}>{item[c.key]}</td>;
				}
			}, this);
		}.bind(this);
		return (
				<tr id={"itemRow_"+data.id} key={data}>{ td(data) }</tr>
		)
	}
});
var WorkflowTable = createReactClass({
		getInitialState: function() {
			return {
				data: [],
				sortDir: "",
				sortColumn: ""
			};
		},
		componentDidMount: function(){
			this.tcId = setTimeout(function(){
				this.setState({data: this.props.dataSource});
			}.bind(this), 10);
		},
		componentWillUnmount: function(){
			clearInterval(this.tcId);
		},
		componentWillReceiveProps: function(nextProps) {
			this.tcId = setTimeout(function(){
				this.setState({data: nextProps.dataSource});
			}.bind(this), 1);
		},
		sort: function( column ){
			if (column != this.state.sortColumn) {
				this.state.sortDir = 'asc';
				this.state.sortColumn = column;
			}
			var sortDir = this.state.sortDir;
			var data = this.state.data;
			if( column != 'undefined' && column){
				sortDir = (sortDir === 'asc' ? 'dsc' : 'asc');
				this.setState({sortDir: sortDir});
				if(this.props.onChangeSort){
					this.props.onChangeSort(this.state.sortColumn,this.state.sortDir === "asc" ? "asc" : "desc");
				}
			}
		},
		handleRowSelected: function(itemId,isSelected){
			this.props.onChangeSelection(this.getSelectedItemsList(itemId,isSelected));
		},
		handleCheckAllSelected: function(e){
			this.props.onCheckAllSelection(e);
		},
		getSelectedItemsList: function(itemid,isSelected){
			if(isSelected){
				$('.itemCheckbox_'+itemid).parents('tr').addClass("selected")
				this.isSelected(itemid) ? this.props.selectedItems : this.props.selectedItems.push(itemid);
			} else {
				$('.itemCheckbox_'+itemid).parents('tr').removeClass("selected")
				if(this.isSelected(itemid)){
					this.props.selectedItems.splice(this.findSelectedIndex(itemid),1);
				}
			}
			return this.props.selectedItems;
		},
		findSelectedIndex: function(id){
			var found = -1;
			if(this.props.selectedItems.length > 0){
				this.props.selectedItems.map(function(itemid, i){
					if(itemid==id){
						found = i;
					}
				},this);
			}
			return found;
		},
		isSelected: function(id){
			return this.findSelectedIndex(id) < 0 ? false : true;
		},
		render: function(){
			var columns = this.props.columns;
			var controller = this.props.controllerName;
			var checkedAll = false;
			if (this.props.selectedItems.length > 0 && this.props.dataSource.length == this.props.selectedItems.length) {
				checkedAll = true;
			}
			if( this.state.data ){
				return (
					<table id={this.props.id} width={this.props.width} cellSpacing="1" cellPadding="0" data-border="0" className="table table-highlight" data-toggle="table">
						<thead>
							<TableHeader columns={columns} onSort={this.sort}  sortDir={this.state.sortDir} sortColumn={this.state.sortColumn} checkedAll={checkedAll} onSelectAll={this.handleCheckAllSelected}/>
						</thead>
						<tbody>
							{
								this.state.data.map(function(item, idx){
									return <TableRow key={idx} controllerName={controller} data={item} columns={columns} actions={this.props.actions} onPreview={this.props.onPreviewErrand} onSelect={this.handleRowSelected} selected={this.isSelected(item.id)}/>;
								},this)
							}
						</tbody>
					</table>
				)
			} else{
				return (
					<table></table>
				)
			}
		}
});
var TableHeader = createReactClass({
	getDefaultProps: function() {
		return {
			checkedAll: false
		}
	},
	render: function() {
		var sortDir = this.props.sortDir;
		var sortColumn = this.props.sortColumn;
		var sortArrow = {
			asc : "▼",
			dsc : "▲"
		}
		var columns = this.props.columns;
		var cell = columns.map(function(c, i){
				var arrow = "\u00a0";
				if (c.key == sortColumn) {
					arrow = sortArrow[sortDir];
				}
				var attach = null;
				if(c.headerType == "checkbox") {
					attach = <span><input id="checkedAll" type="checkbox" ref="globalSelector" onChange={this.changeAllChecks} checked={this.props.checkedAll}/><label htmlFor="checkedAll"></label></span>
				}else if(c.headerType == "image")
					attach = <span className={c.imageClass}></span>;

				return <th onClick={this.sort(c)} className={"headerSortUp"} style={{ textAlign: 'left'}} key={i}>{attach}{c.header}{arrow}</th>;
			}.bind(this));

		return (
			<tr key="headerRow">{ cell }</tr>
		);
	},
	sort: function( column ){		
		return function(event){
			var sortDir = this.props.sortDir;
			if(column.type == "checkbox" || column.sortable == "false") {
				//Do nothing
			}
			else
				this.props.onSort(column.key, sortDir);
		}.bind(this)
	},
	changeAllChecks: function(e) {
		var domNode = ReactDOM.findDOMNode(this.refs.globalSelector)
		var value = domNode.checked;
		this.props.onSelectAll(value);
	}
});
export default WorkflowTable;
