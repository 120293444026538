import React from 'react';
import ReactDOM from 'react-dom';
import createReactClass from 'create-react-class';

function onClickWithFallback({ onClick }) {
	if (typeof onClick === "function") {
		return onClick;
	}
	return () => {};
}

export default createReactClass({
	displayName: "FileUploader",
	getDefaultProps: function(){
		return {
			title: I("Browse"),
			className: "btn btn-default btn-file btn-fileupload",
			attachFile: true,
			multipleFile: false,
			maxFileAllowed: 0 /*no limit*/
		}
	},
	getInitialState: function(){
		return{
			value: ""
		}
	},
	isWithinSizeLimit: function(estimatedSize){
		if(typeof this.props.maxFileAllowed === 'undefined' || this.props.maxFileAllowed <= 0){
			return true // don't care about the file limits
		}
		if(estimatedSize > this.props.maxFileAllowed) {
			return false;
		}
		return true;
	},
	getDOM: function(input){
		return ReactDOM.findDOMNode(input);
	},
	uploadFile: function(e) {
		e.preventDefault();
		if(this.props.onProgress) this.props.onProgress(true);
		var fileUploaded = [];
		var fd = new FormData();
		var domNode = this.getDOM( this.refs.FileUpload );
		if(domNode.files.length > 0){
			if(!this.isWithinSizeLimit(domNode.files[0].size)){
				alert(I("The uploaded file has exceeded the max allowed size."));
				if(this.props.onProgress) this.props.onProgress(false);
				return;
			}
			var boundary = Math.floor(Math.random() * 6)+ '-'+ Math.floor(''+new Date() / 1000) ;
			fd.append( 'uploadfile', domNode.files[0]);
			fd.append( 'fileNameOnly', domNode.files[0].name);
			fd.append( 'eventType', 'upload');
			fd.append( 'random', parseFloat(boundary));
			$.ajax({
				url: webserverRoot + this.props.uploadTo,
				data: fd,
				processData: false,
				contentType: false,
				type: 'POST',
				success: function( rd ){
					this.props.onFileupload( rd );
				}.bind(this)
			});
		}
	},
	uploadFiles: function(e) {
		e.preventDefault();
		if(this.props.onProgress) this.props.onProgress(true);
		var xhr =[];
		var domNode = this.getDOM( this.refs.FileUpload );
		if(domNode.files.length > 0){
			for (var i = 0; i < domNode.files.length; i++) {
				if(!this.isWithinSizeLimit(domNode.files[i].size)){
					alert(I("The uploaded file has exceeded the max allowed size."));
					if(this.props.onProgress) this.props.onProgress(false);
					return;
				}
				var formData = new FormData();
				var boundary = Math.floor(Math.random() * 6)+ ''+ i +''+ Math.floor(''+new Date() / 1000) ;
				formData.append( 'uploadfile', domNode.files[i]);
				formData.append( 'fileNameOnly', domNode.files[i].name);
				formData.append( 'eventType', 'upload');
				formData.append( 'random', parseFloat(boundary));
				if(!$.isEmptyObject(this.props.formData)){
					for(var key in this.props.formData) {
						formData.append( key, this.props.formData[key]);
					}
				}
				xhr[i] = new XMLHttpRequest();
				xhr[i].open("POST", webserverRoot + this.props.uploadTo);
				xhr[i].onreadystatechange = function(event){
					var msg, ro;
					if (event.target.readyState != 4) return;
					if (event.target.status != 200) {
						try {
							ro = JSON.parse(event.target.responseText);
							if (ro.status) {
								msg = ro.status;
							}
							if (ro.error) {
								msg += ' ' + ro.error;
							}
							msg = I("Error: {ERROR}").replace('{ERROR}', msg);
						} catch(e) {
							msg = I("We're sorry, an error occurred while uploading the file");
						}
						alert(msg);
						if(this.props.onProgress) this.props.onProgress(false);
						return;
					}
					ro = JSON.parse(event.target.responseText);
					/*Send callback to parent for every file upload*/
					this.props.onFileupload(ro);
				}.bind(this);
				xhr[i].send(formData);
			};
		}
	},
	render: function() {
		var formFileField = "";
		if( this.props.attachFile )
			formFileField = <span className={this.props.className}>{this.props.title}<input ref="FileUpload" type="file" name="FileUpload" className="upload-file" value={this.state.value} onChange={this.uploadFiles} onClick={onClickWithFallback(this.props)} /></span>;
		if( this.props.multipleFile )
			formFileField = <span className={this.props.className}>{this.props.title}<input ref="FileUpload" type="file" name="FileUpload" className="upload-file" value={this.state.value} onChange={this.uploadFiles} onClick={onClickWithFallback(this.props)} multiple="multiple"/></span>;
		return (<span>{formFileField}</span>);
	}
});
