import React from 'react';
import { adminReEmail, hasPrefix, phoneRegex } from '../common/helpers';
export default {
	getPageUri: function() {
		var queryId = "";
		var query = window.location.hash;
		if(typeof query != 'undefined' && query != "" && query.indexOf('-') != -1 ){
			query = query.split('-')[1];
			queryId = query.replace('/', '');
		}
		return queryId;
	},
	validateEmail: function(value) {
		return (value != "" ? adminReEmail.test(value.trim()) : false);
	},
	isValidPhoneNo: function(value) {
		return (value != "" ? phoneRegex.test(value.trim()) : false);
	},
	addressValidation: function(type, address) {
		let value = address.replace("\t", "").trim();
		if((type === "16" /*Workflow.Errand.SERVICE_VOICE*/
			|| type === "7" /*Workflow.Errand.SERVICE_SMS*/)
			&& this.isValidPhoneNo(value)) {
				return true;
		} else if(type === "5" /*Workflow.Errand.SERVICE_TWITTER*/
			&& hasPrefix(value, "twitter-")) {
				return true;
		} else if(type === "4" /*Workflow.Errand.SERVICE_FACEBOOK*/
			&& (hasPrefix(value, "facebook-")
			|| value.includes("facebook.com"))){
				return true;
		} else if(type === "17" /*Workflow.Errand.SERVICE_INSTAGRAM*/
			&& hasPrefix(value, "instagram-")) {
				return true;
		} else if(type === "6" /*Workflow.Errand.SERVICE_LINKEDIN*/
			&& hasPrefix(value, "linkedin-")){
				return true;
		} else if(type === "14" /*Workflow.Errand.SERVICE_VKONTAKTE*/
			&& hasPrefix(value, "centionvk-")){
				return true;
		} else if(type === "12" /*Workflow.Errand.SERVICE_LINE*/
			&& hasPrefix(value, "centionline-")){
				return true;
		} else if((type === "1" /*Workflow.Errand.SERVICE_EMAIL*/
			|| type === "3") /*Workflow.Errand.SERVICE_CHAT*/
			&& this.validateEmail(value)) {
				return true;
		} else if((type === "21" || type === "22")
			&& hasPrefix(value, "youtube-")) {
				return true;
		}
		return false;
	}
}
